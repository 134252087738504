<template>
    <div class="form" data-app>
        <h1>Retire App Version</h1>
        <div class="submit-success" v-if="success" v-html="success"></div>
        <div class="submit-errors" v-if="error" v-html="error"></div>
        <validation-observer ref="form" v-slot="{ invalid }">
            <form @submit.prevent="retireAppVersion">

                <!-- Platform Dropdown -->
                <div class="form-row">
                    <label for="platformSelect">Platform
                        <fa icon="asterisk" />
                    </label>
                    <v-select id="platformSelect" :items="['iOS', 'Android']" label="Platform" v-model="payload.platform"
                        :rules="[v => !!v || 'Platform is required']" placeholder="Select Platform" required variant="underlined"></v-select>
                </div>

                <!-- App Version Text Input -->
                <div class="form-row">
                    <label for="txtAppVersion">App Version
                        <fa icon="asterisk" />
                    </label>
                    <v-text-field id="txtAppVersion" placeholder="Enter App Version" v-model="payload.appVersion"
                        :rules="[v => !!v || 'App Version is required']" required autocomplete="off"></v-text-field>
                </div>

                <div class="form-row">
                    <button :disabled="invalid || !payload.platform || !payload.appVersion" class="es-btn teal">Go</button>
                </div>
            </form>
        </validation-observer>

    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
    computed: {
        ...mapState('appVersion', [
            'payload',
            'busy',
            'success',
            'error'
        ]),
    },
    methods: {
        ...mapActions('appVersion', ['retireAppVersion']),
    },
}
</script>
<style lang="scss" scoped>
h1 {
    margin-bottom: 1.5em;
}

.form {
    width: 400px;
    margin: auto;

    .submit-errors {
        margin: 1.5em 0;

        & ::v-deep p {
            margin: 0;
            color: $cranberry;
            font-family: $headline;
        }
    }

    .submit-success {
        margin-top: -20px;
        color: $teal;

        & ::v-deep small {
            font-family: 'British Council Sans', sans-serif;
            color: #1a1a1b;
            font-size: 12px;
            display: block;
            padding-left: 3px;
        }
    }

    .busy {
        text-align: center;
        margin: 0;
        font-family: $headline;
    }

    .form-row {
        margin-bottom: 1em;

        & label {
            display: block;
            font-size: 12px;
            cursor: pointer;

            & svg {
                color: $cranberry;
                width: 8px;
                vertical-align: top;
                margin-left: 0.25em;
            }
        }

        &.error {
            & input {
                border-color: $cranberry;
            }
        }

        & input {
            @include input;
        }

        & p.error {
            font-size: 14px;
            color: $cranberry;
            margin-top: 0.25em;
        }

        & button {
            width: 100%;
            margin-top: 2em;
        }
    }
}
</style>
  