var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('h1',[_vm._v("Reinstate Certificate")]),(_vm.success)?_c('div',{staticClass:"submit-success",domProps:{"innerHTML":_vm._s(_vm.success)}}):_vm._e(),(_vm.error)?_c('div',{staticClass:"submit-errors",domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e(),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.placeCertificateOrder($event)}}},[_c('validation-provider',{attrs:{"rules":"uuid","name":"User ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-row",class:{ 'error': errors.length > 0 }},[_c('label',{attrs:{"for":"txtUserId"}},[_vm._v("User ID "),_c('fa',{attrs:{"icon":"asterisk"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.userId),expression:"payload.userId"}],attrs:{"id":"txtUserId","type":"text","placeholder":"Enter User ID"},domProps:{"value":(_vm.payload.userId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "userId", $event.target.value)}}}),(errors.length > 0)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"uuid","name":"Sitting ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-row",class:{ 'error': errors.length > 0 }},[_c('label',{attrs:{"for":"txtSittingId"}},[_vm._v("Sitting ID "),_c('fa',{attrs:{"icon":"asterisk"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.sittingId),expression:"payload.sittingId"}],attrs:{"id":"txtSittingId","type":"text","placeholder":"Enter Sitting ID"},domProps:{"value":(_vm.payload.sittingId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "sittingId", $event.target.value)}}}),(errors.length > 0)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"uuid","name":"Order ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-row",class:{ 'error': errors.length > 0 }},[_c('label',{attrs:{"for":"txtOrderId"}},[_vm._v("Order ID "),_c('fa',{attrs:{"icon":"asterisk"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.orderId),expression:"payload.orderId"}],attrs:{"id":"txtOrderId","type":"text","placeholder":"Enter Order ID"},domProps:{"value":(_vm.payload.orderId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "orderId", $event.target.value)}}}),(errors.length > 0)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)}),_c('div',{staticClass:"form-row"},[_c('button',{staticClass:"es-btn teal",attrs:{"disabled":invalid}},[_vm._v("Go")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }