<template>
  <div class="form">
    <h1>Reinstate Certificate</h1>
    <div
      class="submit-success"
      v-if="success"
      v-html="success"
    ></div>
    <div 
      class="submit-errors"
      v-if="error"
      v-html="error"
    ></div>
    <validation-observer
      ref="form"
      v-slot="{ invalid }"
    >
      <form @submit.prevent="placeCertificateOrder">
        <validation-provider
          rules="uuid"
          name="User ID"
          v-slot="{ errors }"
        >
          <div
            class="form-row"
            :class="{ 'error': errors.length > 0 }"
          >
            <label for="txtUserId">User ID
              <fa icon="asterisk" />
            </label>
            <input
              id="txtUserId"
              type="text"
              placeholder="Enter User ID"
              v-model="payload.userId"
            >
            <p
              v-if="errors.length > 0"
              class="error"
            >
              {{ errors[0] }}
            </p>
          </div>
        </validation-provider>
        <validation-provider
          rules="uuid"
          name="Sitting ID"
          v-slot="{ errors }"
        >
          <div
            class="form-row"
            :class="{ 'error': errors.length > 0 }"
          >
            <label for="txtSittingId">Sitting ID
              <fa icon="asterisk" />
            </label>
            <input
              id="txtSittingId"
              type="text"
              placeholder="Enter Sitting ID"
              v-model="payload.sittingId"
            >
            <p
              v-if="errors.length > 0"
              class="error"
            >
              {{ errors[0] }}
            </p>
          </div>
        </validation-provider>
        <validation-provider
          rules="uuid"
          name="Order ID"
          v-slot="{ errors }"
        >
          <div
            class="form-row"
            :class="{ 'error': errors.length > 0 }"
          >
            <label for="txtOrderId">Order ID
              <fa icon="asterisk" />
            </label>
            <input
              id="txtOrderId"
              type="text"
              placeholder="Enter Order ID"
              v-model="payload.orderId"
            >
            <p
              v-if="errors.length > 0"
              class="error"
            > 
              {{ errors[0] }}
            </p>
          </div>
        </validation-provider>
        <div class="form-row">
          <button
            :disabled="invalid"
            class="es-btn teal"
          >Go</button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('reinstateCertificate', [
      // 'userId',
      // 'sittingId',
      // 'orderId',
      'payload',
      'busy',
      'success',
      'error'
    ]),
  },
  methods: {
    ...mapActions('reinstateCertificate', ['load', 'placeCertificateOrder']),
  },
  beforeMount() {
    const queryparams = this.$route.query
    this.load({ queryparams })
  }
}
</script>
<style lang="scss" scoped>
h1 {
  margin-bottom: 1.5em;
}
.form {
  width: 400px;
  margin: auto;
  .submit-errors {
    margin: 1.5em 0;
    & ::v-deep p {
      margin: 0;
      color: $cranberry;
      font-family: $headline;
    }
  }
  .submit-success {
    margin-top: -20px;
    color: $teal;

    & ::v-deep small {
      font-family: 'British Council Sans', sans-serif;
      color: #1a1a1b;
      font-size: 12px;
      display: block;
      padding-left: 3px;
    }
  }
  .busy {
    text-align: center;
    margin: 0;
    font-family: $headline;
  }

  .form-row {
    margin-bottom: 1em;

    & label {
      display: block;
      font-size: 12px;
      cursor: pointer;

      & svg {
        color: $cranberry;
        width: 8px;
        vertical-align: top;
        margin-left: 0.25em;
      }
    }
    &.error {
      & input {
        border-color: $cranberry;
      }
    }
    & input {
      @include input;
    }
    & p.error {
      font-size: 14px;
      color: $cranberry;
      margin-top: 0.25em;
    }
    & button {
      width: 100%;
      margin-top: 2em;
    }
  }
}
</style>
